import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import SEO from "../components/Seo"

import Hero from "../components/TemplateComponets/Post/Hero"
import Content from "../components/TemplateComponets/Post/Content"
import PostNav from "../components/TemplateComponets/Post/PostNav"

const post = props => {
  const { seoInfo, hero, post } = props.data
  const prevPost = props.pageContext.prev
  const nextPost = props.pageContext.next
  const location = props.location

  return (
    <Layout location={location}>
      <SEO
        title={seoInfo.acf._swb_theme_meta_title}
        description={seoInfo.acf._swb_theme_description}
        metaImg={seoInfo.acf._swb_theme_image.localFile.publicURL}
        location={location.pathname}
      />
      <Hero hero={hero} />
      <Content post={post} />
      <PostNav navInfo={{ prevPost, nextPost }} />
    </Layout>
  )
}

export const singlePostQuery = graphql`
  query singlePost($id: Int!) {
    seoInfo: wordpressPost(wordpress_id: { eq: $id }) {
      acf {
        _swb_theme_meta_title
        _swb_theme_description
        _swb_theme_image {
          localFile {
            publicURL
          }
        }
      }
    }

    hero: wordpressPost(wordpress_id: { eq: $id }) {
      acf {
        _kon_post_featured_image {
          alt_text
          localFile {
            childImageSharp {
              fluid(maxWidth: 2000) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }

    post: wordpressPost(wordpress_id: { eq: $id }) {
      title
      date
      author
      wordpress_id
      slug
      content
      acf {
        _kon_post_author_name
        _kon_post_excerpt
        _kon_post_side_image
      }
    }
  }
`

export default post
