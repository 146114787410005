import React from "react"
import styled from "styled-components"
import moment from "moment"

import {
  H1DarkGrey,
  medWrapper,
  colors,
  B1DarkGrey,
} from "../../../styles/helpers"
import WysiwygSec from "./WysiwygSec"
import PostBankOne from "../../Images/PostBankOne"
import PostBankTwo from "../../Images/PostBankTwo"
import PostBankThree from "../../Images/PostBankThree"
import PostBankFour from "../../Images/PostBankFour"
import PostBankFive from "../../Images/PostBankFive"

const ContentArticle = styled.section`
  .wrapper {
    ${medWrapper};
  }

  .mainTitle {
    border-bottom: solid 3px ${colors.colorPrimary};

    &__wrapper {
      width: calc(100%);

      @media (min-width: 768px) {
        width: calc(100% - 20%);
        margin-left: 25%;
        padding-left: 6rem;
      }
    }

    h1 {
      ${H1DarkGrey};
      display: inline-block;
      position: relative;
      z-index: 5;

      &::after {
        position: absolute;
        right: -1rem;
        bottom: 0.65rem;
        left: -1rem;
        height: 2rem;
        transition: all 0.3s ease;
        background-color: ${colors.colorPrimary};
        content: "";
        z-index: -1;
      }
    }

    &__meta {
      p {
        ${B1DarkGrey};
      }
    }
  }

  .mainContent {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row-reverse;
    justify-content: center;
    width: calc(100%);
    padding: 2.5rem 0;

    @media (min-width: 768px) {
      padding: 7.5rem 0;
    }

    &__content {
      width: 100%;

      @media (min-width: 768px) {
        width: 75%;
        padding-left: 6rem;
      }
    }

    &__sideImg {
      display: none;

      @media (min-width: 768px) {
        display: block;
        width: 25%;
      }
    }
  }
`

const Content = ({ post }) => {
  const sideImageDisplay =
    post.acf._kon_post_side_image === "one" ? (
      <PostBankOne />
    ) : post.acf._kon_post_side_image === "two" ? (
      <PostBankTwo />
    ) : post.acf._kon_post_side_image === "three" ? (
      <PostBankThree />
    ) : post.acf._kon_post_side_image === "four" ? (
      <PostBankFour />
    ) : (
      <PostBankFive />
    )
  return (
    <ContentArticle>
      <div className="wrapper">
        <div className="content">
          <div className="mainTitle">
            <div className="mainTitle__wrapper">
              <h1 dangerouslySetInnerHTML={{ __html: post.title }} />
              <div className="mainTitle__meta">
                <p>
                  <span>{moment(post.date).format("MMM Do, YYYY")}</span> |{" "}
                  <span>
                    Article written by {post.acf._kon_post_author_name}
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div className="mainContent">
            <div className="mainContent__content">
              <WysiwygSec wysiwygSec={post.content} />
            </div>
            <div className="mainContent__sideImg">{sideImageDisplay}</div>
          </div>
        </div>
      </div>
    </ContentArticle>
  )
}

export default Content
