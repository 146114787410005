import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { standardWrapper, Btn1LimeGreen } from "../../../styles/helpers"

const PostNavSection = styled.div`
  padding-bottom: 5rem;

  .wrapper {
    ${standardWrapper};
  }

  .navWrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    a {
      ${Btn1LimeGreen};
      margin: auto 2rem;
    }
  }
`

const PostNav = ({ navInfo }) => {
  return (
    <PostNavSection>
      <div className="wrapper">
        <div className="navWrapper">
          {navInfo.prevPost ? (
            <Link to={`/resources/${navInfo.prevPost}`}>Previous Post</Link>
          ) : null}
          <Link to={"/resources"}>More Resources</Link>
          {navInfo.nextPost ? (
            <Link to={`/resources/${navInfo.nextPost}`}>Next Post</Link>
          ) : null}
        </div>
      </div>
    </PostNavSection>
  )
}

export default PostNav
