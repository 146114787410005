import React from "react"
import styled from "styled-components"

import Wysiwyg from "./Wysiwyg"

const WysiwygSecSection = styled.section`
  width: 100%;
`

const WysiwygSec = ({ wysiwygSec }) => {
  return (
    <WysiwygSecSection>
      <Wysiwyg content={wysiwygSec} />
    </WysiwygSecSection>
  )
}

export default WysiwygSec
