import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"

const PostBankWrap = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 5;
`

const getData = graphql`
  {
    postBank: file(relativePath: { eq: "post-bank-two.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 750) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

const PostBankTwo = () => {
  const data = useStaticQuery(getData)
  const imageData = data.postBank.childImageSharp.fluid
  return (
    <PostBankWrap>
      <Img fluid={imageData} alt="Kona" />
    </PostBankWrap>
  )
}

export default PostBankTwo
